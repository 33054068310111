<template>
  <div class="container-fluid">
    <div class="row" v-if="!exclusive && showSelectAll">
      <div class="col-12 font-weight-bold text-uppercase small d-flex align-items-center mb-2">
        <el-switch class="mr-2 mb-0" v-model="allSelected" @input="toggleSelection"/>
        <label-theme-component>{{ $t('common.check_all') }}</label-theme-component>
      </div>
    </div>
    <div class="row no-gutters">
      <div v-for="(item, index) in listInPage" v-bind:key="`list-${index}`" class="col col-md-6">
        <el-card shadow="hover"
                 class="m-1 bg-transparent"
                 :class="{'text-white': themeMode !== 'light'}"
                 :body-style="{ padding: '10px' }"
        >
          <div v-if="exclusive">
            <el-radio class="d-flex align-items-center mb-0" v-model="itemSelected" :label="item.id">
              <div class="flex-grow-1">
                <slot v-bind:item="item">
                  {{ item }}
                </slot>
              </div>
            </el-radio>
          </div>

          <div v-if="!exclusive" class="d-flex align-items-center">
            <el-switch v-model="item.selected"/>
            <div class="ml-2 flex-grow-1">
              <slot v-bind:item="item">
                {{ item }}
              </slot>
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <div
      v-if="paginate && pages && pages.length > 1"
      class="row justify-content-center">
      <div class="col-auto mt-3">
        <card class="card card-plain mb-1" body-classes="standard-card-body">
          <base-pagination
            class="mb-0"
            v-model="currentPage"
            :per-page=numberItemsPerPage
            :total="list.length"/>
        </card>
      </div>
    </div>
  </div>
</template>

<script>

import {Switch, Card, Radio} from "element-ui";
import BasePagination from "@/components/BasePagination";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import BaseSwitch from "./BaseSwitch";
import {mapGetters} from "vuex";

export default {
  name: "ListSelectorComponent",
  components: {
    BaseSwitch,
    LabelThemeComponent,
    BasePagination,
    [Switch.name]: Switch,
    [Radio.name]: Radio,
    [Card.name]: Card,
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    numberItemsPerPage: {
      type: Number,
      default: 10
    },
    exclusive: {
      type: Boolean,
      default: false
    },
    paginate: {
      type: Boolean,
      default: true
    },
    showSelectAll: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      currentPage: 1,
      itemSelected: null,
      allSelected: false
    }
  },
  computed: {
    ...mapGetters({
      themeMode: 'auth/themeMode'
    }),

    pages: function () {
      return this.$_.chunk(this.list, this.numberItemsPerPage);
    },
    listInPage() {
      return this.paginate ? this.pages[this.currentPage - 1] : this.list;
    }
  },
  methods: {
    getAllItems: function () {
      return this.list;
    },
    getSelectedItems: function () {
      return this.$_.filter(this.list, ['selected', true]);
    },
    getSelectedItem: function () {
      return this.itemSelected;
    },
    toggleSelection() {
      this.$_.each(this.list, (value, key) => {
        this.$set(this.list[key], 'selected', this.allSelected)
      });
    },
  }
}
</script>

<style scoped>

</style>
