import { render, staticRenderFns } from "./ListSelectorComponent.vue?vue&type=template&id=b07c48a2&scoped=true&"
import script from "./ListSelectorComponent.vue?vue&type=script&lang=js&"
export * from "./ListSelectorComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b07c48a2",
  null
  
)

export default component.exports